<template>
  <div class="login-container">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
      <div class="body-container">
        <div class="leftimg">
          <img src="../../../src/assets/loginbg.png" alt="" />
        </div>
        <div class="loginin">
          <h2 class="title mb10">职业院校德育管理系统</h2>
          <div class="tabs">
            <div v-for="(item, index) in tab" :key="index" :class="{ tab_active: current == index, tab_item: current != index }" @click="changeTab(index)">
              {{ item }}
            </div>
          </div>

          <el-form-item label-width="40px" class="border" v-if="current == 0">
            <div slot="label" class="label">
              <i class="el-icon-user"></i>
            </div>
            <el-input v-model="formInline.phoneNum" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label-width="40px" class="border" v-if="current == 0">
            <div slot="label" class="label">
              <i class="el-icon-lock"></i>
            </div>
            <el-input type="password" v-model="formInline.passWordLogin" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item class="mgt36" v-if="current == 0">
            <el-button type="primary" @click="submitFormLoginPwd(formInline)" class="login-btn" round>登录</el-button>
          </el-form-item>


          <el-form-item label-width="40px" class="border" v-if="current == 1">
            <div slot="label" class="label">
              <i class="el-icon-user"></i>
            </div>
            <el-input v-model="formInline.phoneNum" placeholder="请输入手机号" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label-width="40px" class="border" v-if="current == 1">
            <div slot="label" class="label">
              <i class="el-icon-lock"></i>
            </div>
            <el-input v-model="formInline.checkCode" placeholder="请输入验证码" style="width:240px;"></el-input>
            <el-button type="primary" v-if="showSendBtn" @click="sendCheckCode(formInline)" class="send-btn">发送验证码</el-button>
            <el-button type="info" v-if="!showSendBtn" class="send-btn">{{ leftSeconds }} s</el-button>
          </el-form-item>

          <el-form-item class="mgt36" v-if="current == 1">
            <el-button type="primary" @click="submitFormBindPhone(formInline)" class="login-btn" round>绑定手机号</el-button>
          </el-form-item>

        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import * as api from "@/api/login";
  import { getCurrUserItem } from "@/api/users";
  import { setToken } from "@/utils/token.js";
  export default {
    name: "Login",
    components: {
    },
    data() {
      return {
        current: 1, // 当前选中的 tab-index
        tab: [ "账号登录", "绑定手机号"],
        formInline: {
          openID : "",
          openKey : "",

          phoneNum: "",
          passWordLogin: "",
          newPassWord: null,
          checkCode: "",
          rose: "",
        },
        loginForm: {
          phoneNum: "",
          passWordLogin: "",
        },
          
        timer: null, // 倒计时
        leftSeconds: 0,
        showSendBtn: true,

        activeName: "second",
        // activeName: "first",

        loginRules: {
          phoneNum: [{ required: true, trigger: "blur" }],
          passWordLogin: [{ required: true, trigger: "blur" }],
        },
        loading: false,
        passwordType: "password",
        redirect: undefined,
      };
    },
    // vue 在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作。
    mounted() {
      // 接收url参数
      var query = this.$route.query;
      // ?openID=
      let openID = query.openID;
      let openKey = query.openKey;
      if (openID != null && openID.length > 0 && openKey != null && openKey.length > 0)
      {
        this.formInline.openID = openID;
        this.formInline.openKey = openKey;
      }
      else
      {
        this.$router.push({ path: "/login", });
      }
    },
    methods: {
      // 修改密码
      btn() {
        this.$router.push({
          path: "/home",
        });
      },
      changeTab(index) {
        this.current = index;
      },
      // 执行 账号+密码 登录
      submitFormLoginPwd(formName) {
        if (formName.phoneNum == "" || formName.phoneNum.length < 11) {
          this.$message.error("请输入手机号");
        } if (formName.passWordLogin == "" || formName.passWordLogin == "") {
          this.$message.error("请输入密码");
        } else {
          api.loginByPwd(formName).then((res) => {
            if (res.status == 2) {
              setToken(res.data, "登录");
              this.$message.success(res.msg);
              this.$router.push({
                path: "/userArgument",
              });
              this.$nextTick(() => {
                this.getCurrUserItem();
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      },

      // 倒计时
      beginTimerRun() {
        this.showSendBtn = false
        this.timer = setInterval(()=>{
          this.leftSeconds--
          if(this.leftSeconds === 0){
            this.showSendBtn = true
            clearInterval(this.timer)
          }
        },1000)
      },

      // 发送短信验证码
      sendCheckCode(formName) {
        if (formName.phoneNum == "" || formName.phoneNum.length < 11) {
          this.$message.error("请输入手机号码");
        } else {
          // 发送短信验证码
          api.sendCheckCode(formName).then((res) => {
            if (res.status == 1 || res.status == 200) {
              this.$message.success(res.data);
              // 60秒倒计时
              // beginTimerRun();
              this.showSendBtn = false
              this.leftSeconds = 60;
              this.timer = setInterval(()=>{
                this.leftSeconds--
                if(this.leftSeconds <= 0){
                  this.leftSeconds = 0;
                  this.showSendBtn = true
                  clearInterval(this.timer)
                }
              },1000)
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      },
      // 执行 手机号+验证码+openKey+openID 绑定
      submitFormBindPhone(formName) {
        if (formName.phoneNum == "") {
          this.$message.error("请输入手机号码");
        } 
        else if (formName.newPassWord == "") {
          this.$message.error("请输入新密码");
        } 
        else if (formName.checkCode == "") {
          this.$message.error("请输入验证码");
        } else {
          api.loginByPhoneBind(formName).then((res) => {
            if (res.status == 2) {
              setToken(res.data, "登录");
              this.$message.success(res.msg);
              this.$router.push({
                path: "/userArgument",
              });
              this.$nextTick(() => {
                this.getCurrUserItem();
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      },

      // 角色接口
      getCurrUserItem() {
        getCurrUserItem().then((res) => {
          if (res.status == 1) {
            console.log("角色接口");
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  $bg: #205ce2;
  $light_gray: #fff;
  $cursor: #fff;
  .login-container {
    background: $bg;
    .el-input {display: inline-block;height: 47px;width: 100%;border: 1px solid rgba(255, 255, 255, 0.1);
      input {border: 0px;height: 47px;}
    }
    .el-form-item.border {border-bottom: 1px solid #205ce2;}
  }
</style>

<style lang="scss" scoped>
  $bg: #205ce2;
  $dark_gray: #889aa4;
  $light_gray: #eee;

  #wx_login_container{text-align:center;}
  .login-container {
    min-height: 100vh;
    width: 100%;
    background-color: $bg;
    overflow: hidden;
    .login-form {
      position: relative;
      width: 960px;
      max-width: 100%;
      margin: 0 auto;
      margin-top: 150px;
      overflow: hidden;
      border-radius: 8px;
      box-shadow: 1px 1px 4px #ddd;
      background: #ffffff;
    }
    .svg-container {
      padding: 6px 5px 6px 15px;
      color: $dark_gray;
      vertical-align: middle;width: 30px;display: inline-block;
    }

    .body-container {
      width: 100%;
      padding: 24px;
      .leftimg {
        width: 450px;
        height: 400px;
        float: left;
        img {width: 100%;}
      }
      .loginin {
        float: left;
        width: 400px;
        margin-top: 10px;
        .title {text-align: center;color: #205ce2;font-size: 30px;}
        .forget-password {
          margin-bottom: 6px;
          a { font-weight: normal !important;}
        }
        .label {color: #205ce2;font-size: 20px;line-height: 47px;text-align: center;}
        .send-btn {width: 110px;height: 40px; margin-bottom:4px;}
        .login-btn {width: 100%;height: 48px;}
      }
    }
    .show-pwd {position: absolute;right: 10px;top: 7px;font-size: 16px;color: $dark_gray;cursor: pointer;user-select: none;}
  }
  /deep/.el-tabs__nav is-top {display: flex;justify-content: space-around;width: 100%;background: blue;}
  .f1 {height: 500px;width: 75%;margin: 0 auto;}
  .father { position: relative; width: 83%; margin: 0 auto;}
  /deep/.el-tabs__item { width: 100%; padding: 0px;}
  .tabs { font-size: 22px; width: 100%;  margin-bottom: 30px; display: flex; justify-content: space-around;}
  .tab_active { color:#347bf9; cursor: pointer; padding: 10px 40px; border-bottom:2px solid #347bf9;}
  .tab_item { color: #c9c5c5; cursor: pointer; padding: 10px 40px; border-bottom:2px solid white;}
</style>
